import { AutoCompleteAddress } from "../../components/GoogleAutoCompleteAddressHomeInternet";
import { HomeInternetPlan } from "../../home-internet-content/constant-and-types";
import {
  ACTION_TYPE,
  UPDATE_ADDRESS,
  UPDATE_PROMO_CODE,
  UPDATE_SELECTED_PLAN,
  UPDATE_SELECTED_PLAN_ID,
} from "./homeInternetActions";

const initialState = {
  selectedPlanId: 0,
  selectedPlan: null as HomeInternetPlan,
  address: null as null | AutoCompleteAddress,
  promoCode: "",
};

const homeInternetReducer = (state = initialState, action: ACTION_TYPE) => {
  switch (action.type) {
    case UPDATE_ADDRESS:
      return { ...state, address: action.payload };
    case UPDATE_PROMO_CODE:
      return { ...state, promoCode: action.payload };
    case UPDATE_SELECTED_PLAN_ID:
      return { ...state, selectedPlanId: action.payload };
    case UPDATE_SELECTED_PLAN:
      return { ...state, selectedPlan: action.payload };
    default:
      return state;
  }
};

export default homeInternetReducer;
