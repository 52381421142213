import { AutoCompleteAddress } from "../../components/GoogleAutoCompleteAddressHomeInternet";
import { HomeInternetPlan } from "../../home-internet-content/constant-and-types";

export const UPDATE_SELECTED_PLAN = "homeInternet/updateSelectedPlan";
export const UPDATE_ADDRESS = "homeInternet/updateAddress";
export const UPDATE_PROMO_CODE = "homeInternet/updatePromoCode";
export const UPDATE_SELECTED_PLAN_ID = "homeInternet/updateSelectedPlanId";

export type UPDATE_ADDRESS_ACTION = {
  type: typeof UPDATE_ADDRESS;
  payload: AutoCompleteAddress;
};

export type UPDATE_SELECTED_PLAN_ACTION = {
  type: typeof UPDATE_SELECTED_PLAN;
  payload: HomeInternetPlan;
};

export type UPDATE_SELECTED_PLAN_ID_ACTION = {
  type: typeof UPDATE_SELECTED_PLAN_ID;
  payload: number;
};

export type UPDATE_PROMO_CODE_ACTION = {
  type: typeof UPDATE_PROMO_CODE;
  payload: string;
};

export type ACTION_TYPE =
  | UPDATE_ADDRESS_ACTION
  | UPDATE_SELECTED_PLAN_ACTION
  | UPDATE_SELECTED_PLAN_ID_ACTION
  | UPDATE_PROMO_CODE_ACTION;

export const updateAddress =
  (address: AutoCompleteAddress) => (dispatch: any) => {
    dispatch({ type: UPDATE_ADDRESS, payload: address });
  };

export const updateSelectedPlan = (plan: HomeInternetPlan) => (dispatch: any) =>
  dispatch({
    type: UPDATE_SELECTED_PLAN,
    payload: plan,
  });

export const updatePromoCode = (promoCode: string) => (dispatch: any) =>
  dispatch({ type: UPDATE_PROMO_CODE, payload: promoCode });

export const updateSelectedPlanId = (planId: number) => (dispatch: any) =>
  dispatch({
    type: UPDATE_SELECTED_PLAN_ID,
    payload: planId,
  });
