import { combineReducers } from "redux";
import routeReducer from "./routeReducer";
import shopReducer from "./shopReducer";
import defaultPlansReducer from "./defaultPlansReducer";
import plansReducer from "./plansReducer";
import getCountriesApiReducer from "./getCountriesApiReducer";
import homeInternetReducer from "../home-internet";

export const rootReducer = combineReducers({
  shop: shopReducer,
  route: routeReducer,
  defaultPlans: defaultPlansReducer,
  plans: plansReducer,
  countryCodes: getCountriesApiReducer,
  homeInternet: homeInternetReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
